<template>
    <div class="root horizon">
        <!--左侧操作-->
       <div class="root_left common-txt">
          <div class="title-left">批量停复机操作</div>
          <div class="left-content">
              <div style="padding-top: 5px;margin-left:20px;">停机复机: </div>
              <div style="margin-top: 5px;">
                  <el-select v-model="isStop" style="width:180px;margin-left: 20px;" @change="inputSelect"
                             placeholder="请选择停复机" size="small">

                  </el-select>
              </div>
              <div style="margin-left:20px;margin-top: 5px;">输入卡号:</div>
              <el-input v-model="form.text"  :rows="5"
                        type="textarea"
                        size="small"
                        style="margin-top: 5px;width: calc(100% - 40px);margin-left: 20px;margin-right: 20px; "
                        placeholder="请输入卡号（msisdn/iccid/imsi），每行一个，最多50个" />

              <div style="padding-top: 10px;margin-left:20px;">选择文件: </div>
              <div></div>
              <div style="padding-top: 10px;margin-left:20px;margin-right: 20px;">支持.xlsx格式，示例文件：导入文件请参考模板</div>

              <div style="padding-top: 10px;margin-left:20px;">任务名称: </div>
              <el-input v-model="form.text"  size="small"
                        style="margin-top: 5px;width: calc(100% - 40px);margin-left: 20px;margin-right: 20px; "
                        placeholder="请输入" />

              <el-button size="small" type="primary" class="button">提交</el-button>

              <div style="padding-top: 10px;margin-left:20px;margin-right: 20px;clear:both;">
                  本平台支持操作移动物联卡单日单卡停机或复机不超过2次，电信、联通物联卡不限次数。平台停复机操作功能，有一定时延，请勿频繁操作停复机。如有疑问请联系客户经理或客服。
              </div>

          </div>
       </div>

        <!--右侧记录-->
       <div class="root_right">
            <div class="title-right">批量停复机记录</div>
             <!--列表-->
            <div class="content-right">
                <el-table :data="dataList" border  size="small">
                    <el-table-column type="index" label="序号"  align="center" width="50px"  />
                    <el-table-column prop="name" label="任务名称" width="auto" align="center" min-width="100px" />
                    <el-table-column prop="name" label="完成百分比" width="auto" align="center" min-width="100px" />
                    <el-table-column prop="name" label="状态" width="auto" align="center" min-width="80px" />
                    <el-table-column prop="name" label="提交用户" width="auto" align="center" min-width="120px" />
                    <el-table-column prop="name" label="处理结果" width="auto" align="center" min-width="180px" />
                    <el-table-column prop="name" label="开始时间" width="auto" align="center" min-width="130px" />
                    <el-table-column prop="name" label="结束时间" width="auto" align="center" min-width="130px" />
                    <el-table-column prop="name" label="创建时间" width="auto" align="center" min-width="130px" />
                </el-table>
                <div class="bottom">
                    <el-pagination background layout="prev,sizes, pager, next" :total="toaltal"
                                   @size-change="handleSizeChange"
                                   @current-change="handleCurrentChange">
                    </el-pagination>
                </div>
            </div>
       </div>

    </div>
</template>

<script>
export default {
  name: "automaticStopRunMachine",
  data(){
      return {
          isStop:'',
          form:{
              text:''
          },
          toaltal:10,
          dataList:[{},{}]
      }
  },
  mounted() {
  },
  methods:{
      /** 停机或者复机 */
      inputSelect:function (e) {
         console.log(e)
      },
      //分页回调
      handleSizeChange:function(number){
          console.log('number1',number)
      },
      //分页回调
      handleCurrentChange:function(number){
          console.log('number2',number)
      },
  }
}
</script>

<style scoped>
    @import "../../assets/css/global.css";
    .common-txt{
        color: #7F7F7F;
        font-size: 14px;
    }
    .root{
        background: #ACE1F5;
        width: 100%;
        height: 100%;
        position: relative;
        margin-bottom: 20px;
    }
    .root_left{
       min-width: 250px;
       width: 26%;
       margin-right: 10px;
       border-radius: 5px;
        height: 100%;
    }
    .root_right{
        min-width: 770px;
        height: 100%;
        width: 73%;
        border-radius: 5px;
    }
    .title-left{
        height: 40px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        color: white;
        font-size: 18px;
        background: #FFB75D;
        border-top-left-radius:5px;
        border-top-right-radius: 5px;
    }
    .title-right{
        height: 40px;
        display: flex;
        flex-direction: row;
        align-items: center;
        color: white;
        font-size: 18px;
        background: #3296ED;
        border-top-left-radius:5px;
        border-top-right-radius: 5px;
        padding-left: 20px;
    }
    .left-content{
        width: 100%;
        height: calc(100% - 40px);
        background: #F9F2D2;
    }
    .content-right{
        width: calc(100% - 20px);
        height: calc(100% - 60px);
        background: #F9F2D2;
        padding: 10px;
        position: relative;
    }
    .button{
        float: right;
        margin-right: 20px;
        margin-top: 10px;
    }
    .bottom{
        width: 100%;
        text-align: center;
        margin-top: 10px;
        position: absolute;
        bottom: 10px;
    }
    .content-right>>>.el-table th.el-table__cell>.cell{
        background: #FFB75D;
    }
    .content-right>>>.el-table_1_column_1{
        padding: 0px;
    }

</style>可以
